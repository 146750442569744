enum REGISTERED_APPS {
  CANDYCART = 'candycart',
  CANDYRACK = 'candyrack',
  LAST_UPSELL = 'last_upsell',
  PRODUCT_PAGE_PLACEMENT = 'product_page_placement',
}

export interface SupportedFeatures extends Record<string, boolean> {
  embedBlock: boolean;
  PPE_ADMIN: boolean;
}

export { REGISTERED_APPS };

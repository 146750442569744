import { logError } from '../shared/utils/logging';

export function filterCheckoutForms(button: HTMLElement) {
  try {
    const form = button.closest('form');
    const action = form?.attributes.getNamedItem('action');
    if (action) {
      return action.value !== '/checkout';
    }
    return true;
  } catch (error) {
    // @ts-ignore
    logError('filterCheckoutForms', error);
    return true;
  }
}

export function getAddToCartSelectors(): string {
  return [
    '.add-to-cart',
    '.add_to_cart',
    '.add-to-cart-button',
    '.add_to_cart_button',
    '.button-add-to-cart',
    '.button_add_to_cart',
    '.add-to-cart-btn',
    '.add_to_cart_btn',
    '.btn-add-to-cart',
    '.btn_add_to_cart',
    '.addToCart',
    '.AddToCart',
    '.AddtoCart',
    '.addtocart',
    '.btn-addtocart',
    '.btn--sticky_addtocart',
    '.qsc-btn--add-to-cart',
    '#add-to-cart',
    '#add-to-cart-btn',
    '#btn-add-to-cart',
    '#addToCartButton',
    '#addToCartBtn',
    '#btnAddToCart',
    '#AddToCart',
    '#shopify_add_to_cart',
  ]
    .reduce(
      (acc, val) => {
        acc.push(`button${val}:not(.cr-ignore)`);
        acc.push(`input${val}:not(.cr-ignore)`);
        acc.push(`a${val}:not(.cr-ignore)`);
        return acc;
      },
      [
        'form[action*="/cart/add"] button[type=submit]:not(.shopify-payment-button__button):not(.shopify-payment-button__more-options)',
        'form[action*="/cart/add"] input[type=submit]',
        'a[href*="/cart/add"]',
        'form[action="/cart/add"] #button-cart', // pressreleasehub.myshopify.com
        '.product-atc-btn', // navinastyles.myshopify.com
        'form[action="/cart/add"] .shg-btn', // zzonk.myshopify.com
        'form[action*="/cart/add"] [data-product-add]', // vitamins-lv.myshopify.com
        '.btn--add-to-cart', // ebuy-world.myshopify.com
        '.product-form--atc-button', // bezali.com
        '.cart__checkout-btn', // tallnya.myshopify.com
        '.product-atc__button', // FBBCApproved.com and TruLean.com
        '.complete-design-a', // cardsplug.com
        '.product_buttonContainer-addToCart a', // messyweekend.jp
        '#mwAddToCart',
        'button[data-zp-link-type="cart"]',
        'button[data-pf-type="ProductATC"]',
        '[data-action="add-to-cart"]',
        '[data-action="AddToCart"]',
        '[name=add]',
        '[name=addToCart]',
        '[name=AddToCart]',
        '.g-stickybar-buynow', // https://apps.shopify.com/straight-to-checkout-skip-cart
        '.product-form--add-to-cart',
        '.product-form__cart-submit',
        '.addCart',
        '.product-form__cart-submit div',
        '.addtocart-button-active',
        'button.product-page-add-to-cart',
        '.shopify-product-form .buy-buttons button[type="submit"] *', // https://themes.shopify.com/themes/impact/styles/sound
      ]
        .map((selector) => `${selector}:not(.cr-ignore)`)
        .concat(window.CANDYRACK_CUSTOM_BUTTON_SELECTORS || []),
    )
    .join(', ');
}

export function getCheckoutButtonSelectors() {
  return [
    'button[name="checkout"]',
    'input[name="checkout"]',
    'form[action="/checkout"] [type="submit"]',
    '[href*="checkout"]',
    '[href$="checkout"]',
    'button[value="Checkout"]',
    'input[value="Checkout"]',
  ]
    .map((selector) => `${selector}:not(.candy-cloned)`)
    .concat(window.CANDYCART_CUSTOM_BUTTON_SELECTORS || [])
    .join(',');
}

export function parseIgnoreSelectors(selectors: string | string[] | undefined) {
  if (selectors?.length === 1) {
    return selectors[0];
  }
  return Array.isArray(selectors) ? selectors.join(', ') : selectors;
}
